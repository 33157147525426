<template>
  <div :id="id" class="text-xs-center">
    <v-dialog
      v-model="isOn"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          {{ $t('ajuda.titulo') }}
        </v-card-title>

        <v-card-text>
          <v-textarea
            outline
            name="input-7-1"
            :label="$t('ajuda.label')"
            v-model="message"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            @click="isOn = false"
          >
            {{ $t('ajuda.cancelar') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="send"
          >
            {{ $t('ajuda.enviar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HelpDialogService from './HelpDialogService'

export default {
  name: 'HelpDialog',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isOn: false,
      title: String,
      message: ''
    }
  },
  created () {
    HelpDialogService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
      this.title = event.title
    })
  },
  destroyed () {
    HelpDialogService.unsubscribe(this.id)
  },
  methods: {
    send () {
      this.$emit('sendHelpInformation', this.message)
      this.isOn = false
      this.message = ''
    }
  }
}
</script>
