<template>
  <v-container
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <logo-toolbar />
    <v-layout align-center>
      <v-flex text-xs-center>
        <h1 class="display-2 primary--text">{{ $t('notFound.title') }}</h1>
        <p>{{ $t('notFound.message') }}</p>
        <v-btn
          :to="path"
          outline
          color="primary"
        >
          {{ $t('notFound.button') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import LogoToolbar from '@/components/toolbar/LogoToolbar'
import util from '@/router/util'

export default {
  name: 'FourOhFour',
  data: () => {
    return {
      util: util,
      path: '/'
    }
  },
  components: {
    LogoToolbar
  },
  created () {
    this.$ssrContext && this.$ssrContext.res.status(404)
    if (this.$route.params.path) {
      this.path = this.$route.params.path
    }
  }
}
</script>
