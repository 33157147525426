<template>
  <v-layout column wrap>
    <span class="subheading">Qual seção do Vida e Saúde que você mais gosta de assistir?</span>
    <span class="body-1">(Enumerar de 1 a 4, sendo 1 a que mais aprecia e 4 a que menos lhe atrai)</span>
    <v-layout row justify-center>
      <v-flex xs3 md1>
        <v-select
          data-cy="sectionAttracts1"
          v-model="answers[0]"
          :items="options"
          label="0"
          :disabled="disabled"
          solo
          required
        ></v-select>
      </v-flex>
      <v-flex class="mt-4">{{ items[0] }}</v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs3 md1>
        <v-select
          data-cy="sectionAttracts2"
          v-model="answers[1]"
          :items="options"
          label="0"
          :disabled="disabled"
          solo
          required
        ></v-select>
      </v-flex>
      <v-flex class="mt-4">{{ items[1] }}</v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs3 md1>
        <v-select
          data-cy="sectionAttracts3"
          v-model="answers[2]"
          :items="options"
          label="0"
          :disabled="disabled"
          solo
          required
        ></v-select>
      </v-flex>
      <v-flex class="mt-4">{{ items[2] }}</v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-flex xs3 md1>
        <v-select
          data-cy="sectionAttracts4"
          v-model="answers[3]"
          :items="options"
          label="0"
          :disabled="disabled"
          solo
          required
        ></v-select>
      </v-flex>
      <v-flex class="mt-4">{{ items[3] }}</v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import DialogService from '@/components/dialogs/generic/DialogService'

export default {
  name: 'EnumerateInput',
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      options: [0, 1, 2, 3, 4],
      answers: [],
      results: []
    }
  },
  methods: {
    validate () {
      if (this.isValidAnswer()) {
        this.answers.forEach((element, index) => {
          this.results[index] = `${this.answers[index]} - ${this.items[index]}`
        })
      }
      return this.results
    },
    isValidAnswer () {
      if (!this.isAnswersFilled()) {
        DialogService.on('DefaultDialog', 'Campo obrigatório', 'Enumerar de 1 a 4 as seções do programa, sendo 1 a que mais aprecia e 4 a que menos lhe atrai.')
        return false
      }

      if (this.isRepeatAnswer()) {
        DialogService.on('DefaultDialog', 'Atenção', 'Há valores repetidos ao enumerar as seções do programa.')
        return false
      }

      return true
    },
    isAnswersFilled () {
      const zeros = this.answers.filter(item => item === 0)
      return this.answers.length === 4 && zeros.length === 0
    },
    isRepeatAnswer () {
      const repetition = this.answers.filter((item, index) => this.answers.indexOf(item) !== index)
      return repetition.length > 0
    }
  }
}
</script>

<style>

</style>
