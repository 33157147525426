<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height style="background-color: #C52C34">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4 xl3>
            <v-card rounded class="pa-4 elevation-1">
              <v-layout align-center justify-center class="pt-4">
                <p class="title">{{ $t('esqueciMinhaSenha.titulo') }}</p>
              </v-layout>
              <v-card-text>
                <v-form v-model="valid" ref="form" @submit="send">
                  <v-text-field
                    data-cy="email"
                    :label="$t('esqueciMinhaSenha.email')"
                    name="login"
                    prepend-icon="email"
                    type="email"
                    v-model="email"
                    :rules='emailRules'
                    :disabled="disabled"
                    required
                  ></v-text-field>

                  <v-spacer></v-spacer>
                  <v-layout justify-end>
                    <v-btn block data-cy="signin" class="mt-4 mb-2 pa-4" type="submit" color="primary" :disabled="!valid || disabled" @click="send">{{ $t('esqueciMinhaSenha.botao') }}</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import SnackbarService from '@/components/snackbar/SnackbarService'

export default {
  name: 'ForgotPassword',
  data: () => {
    return {
      valid: false,
      email: null,
      disabled: false,
      emailRules: []
    }
  },
  mounted () {
    this.emailRules = [
      v => !!v || this.$t('validacao.campoObrigatorio'),
      v => /.+@.+/.test(v) || this.$t('validacao.emailValido')
    ]
  },
  methods: {
    send (e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        firebase.auth().sendPasswordResetEmail(this.email).then(() => {
          SnackbarService.on('Snackbar', this.$t('esqueciMinhaSenha.emailEnviado'))
          this.disabled = true
        }).catch(error => {
          if (error.code === 'auth/user-not-found') {
            SnackbarService.on('Snackbar', this.$t('esqueciMinhaSenha.emailNaoCadastrado'))
          } else {
            SnackbarService.on('Snackbar', this.$t('esqueciMinhaSenha.erroEnviarEmail'))
          }
        })
      }
    }
  }
}
</script>
