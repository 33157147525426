<template>
  <v-layout row justify-start fill-height>
    <v-flex v-if="items.length">
      <v-list two-line class="fill-height">
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header">{{ item.header }}</v-subheader>

          <v-list-tile :key="item.title" avatar @click="viewInformation(item.interviewee)">
            <v-list-tile-avatar>
              <img :src="item.avatar" alt="Avatar">
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title v-html="item.title"></v-list-tile-title>
              <v-list-tile-sub-title class="text--primary">{{ item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-list-tile-title v-if="item.code" v-html="item.code"></v-list-tile-title>
              <v-list-tile-sub-title>{{ $t('tab.historico.escore') }}: {{ item.escore }}</v-list-tile-sub-title>
            </v-list-tile-action>
          </v-list-tile>

          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
    <v-layout v-else-if="empty" align-center justify-center row>
      <div class="subheading font-weight-light">{{ $t('tab.historico.naoExistemQuestionarios') }}</div>
    </v-layout>
    <Progress id="Progress" />
  </v-layout>
</template>

<script>
import firebase from 'firebase/app'
import util from '@/router/util'
import { mapGetters, mapActions } from 'vuex'
import ProgressService from '@/components/progress/ProgressService'
import Progress from '@/components/progress/Progress'

export default {
  name: 'ResearchList',
  data: () => {
    return {
      items: [],
      researches: [],
      empty: false
    }
  },
  components: {
    Progress
  },
  mounted () {
    ProgressService.on('Progress')

    firebase.app().database().ref('users/' + this.getUserData.uid + '/researches')
      .once('value')
      .then(snap => {
        if (snap.val()) {
          let researches = Object.values(snap.val())
          this.researches = researches.sort((a, b) => new Date(b.date) - new Date(a.date))
          researches.forEach(item => {
            let i = {
              avatar: item.interviewee.gender === 'male' ? require('@/assets/male.png') : require('@/assets/female.png'),
              title: item.interviewee.nameInitials,
              subtitle: item.date,
              escore: item.answers.reduce((a, b) => a + b, 0),
              interviewee: item.interviewee,
              code: item.code
            }
            this.items.push(i)
          })
        } else {
          this.empty = true
        }

        this.saveTotalResearches(this.items.length)

        ProgressService.off('Progress')
      })
      .catch(error => {
        console.log(error)
      })
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  methods: {
    ...mapActions(['saveTotalResearches']),
    viewInformation (interviewee) {
      this.$router.push({
        name: 'Form',
        params: {
          interviewee,
          pathBackButton: util.path.research.TAB_HISTORY
        }
      })
    }
  }
}
</script>
