<template>
  <v-btn round large class="pa-4" :href="knowMoreUrl()" target="_blank" download color="primary">{{ $t('home.section.saberMais.botao') }}</v-btn>
</template>

<script>
export default {
  name: 'KnowMoreButton',
  data () {
    return {
    }
  },
  methods: {
    knowMoreUrl () {
      return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/panfleto_q8rn.pdf?alt=media&token=05a8dceb-6663-4af9-acc3-a0516bc2f352'
    }
  }
}
</script>
