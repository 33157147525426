<template>
  <v-layout>
    <toolbar :pathBackButton="util.path.HOME">
      <img :src="getImage" alt="Logo referente ao tema" width="40px" height="40px">
      <h3 class="white--text title pl-3">{{ getTheme }}</h3>
    </toolbar>
    <v-layout column fill-height>
      <v-flex>
        <v-card elevation="4">
          <v-card-text class="pa-2">
            <div class="text-xs-center">
              <v-pagination
                circle
                v-model="page"
                :length="getQuestions.length"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
        <v-container>
        <v-layout align-center justify-center>
          <v-flex>
            <router-view />
          </v-flex>
        </v-layout>
        </v-container>
    </v-layout>
    <v-fab-transition>
      <v-btn
        aria-label="próxima questão"
        data-cy="forward"
        dark
        fab
        fixed
        bottom
        right
        color="primary"
        @click="next"
      >
        <v-icon>arrow_forward</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-layout>
</template>

<script>
import Toolbar from '@/components/toolbar/Toolbar'
import DialogService from '@/components/dialogs/generic/DialogService'
import util from '@/router/util'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Questionnaire',
  components: {
    Toolbar
  },
  data: () => {
    return {
      util: util
    }
  },
  mounted () {
    document.querySelector('#app > div.application--wrap > main > div > div > div > div.flex > div > div > div > ul > li:nth-child(1) > button').setAttribute('aria-label', 'Voltar uma questão')
    document.querySelector('#app > div.application--wrap > main > div > div > div > div.flex > div > div > div > ul > li:nth-child(7) > button').setAttribute('aria-label', 'Avançar uma questão')
  },
  computed: {
    ...mapGetters(['getQuestions', 'getPageNumber', 'getQuestionnaireType']),
    page: {
      get: function () {
        return parseInt(this.$route.params.num)
      },
      set: function (page) {
        this.updatePageNumber({
          page,
          questionnaireType: this.getQuestionnaireType,
          isResearch: false
        })
      }
    },
    getTheme () {
      return this.getQuestions[this.$route.params.num - 1].tema.titulo
    },
    getImage () {
      return require('@/' + this.getQuestions[this.$route.params.num - 1].tema.caminhoImagemTema)
    }
  },
  methods: {
    ...mapActions(['updatePageNumber', 'clearInterviewee']),
    next () {
      if (this.currentQuestionAnswered()) {
        if (!this.isLastQuestion()) {
          this.$route.params.num++
          this.updatePageNumber({
            page: parseInt(this.$route.params.num),
            questionnaireType: this.getQuestionnaireType,
            isResearch: false
          })
        } else if (this.allQuestionsAnswered()) {
          this.$router.push(util.path.SCORE)
        } else {
          DialogService.on('DefaultDialog', this.$t('questionario.respostasObrigatorias'), this.$t('questionario.preenchaAsQuestoes'))
        }
      } else {
        DialogService.on('DefaultDialog', this.$t('questionario.respostaObrigatoria'), this.$t('questionario.escolhaUmaResposta'))
      }
    },
    isLastQuestion () {
      return this.getQuestions[this.$route.params.num - 1].numero === this.getQuestions.length
    },
    currentQuestionAnswered () {
      return this.getQuestions[this.$route.params.num - 1].opcaoSelecionada !== ''
    },
    allQuestionsAnswered () {
      return this.getQuestions.filter(question => question.opcaoSelecionada === '').length === 0
    }
  }
}
</script>
