<template>
  <v-layout column>
    <Toolbar :title="$t('escore.titulo')" itemsRight>
      <v-btn icon>
        <v-icon data-cy="home" color="white" @click="backMenu">home</v-icon>
      </v-btn>
    </Toolbar>
    <router-view />
  </v-layout>
</template>
<script>
import Toolbar from '@/components/toolbar/Toolbar'
import util from '@/router/util'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ResearchScore',
  data: () => {
    return {
      items: [],
      classification: '...'
    }
  },
  components: {
    Toolbar
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  methods: {
    ...mapActions(['logout']),
    backMenu () {
      if (this.getUserData.isAnonym) {
        this.logout()
        this.$router.push(util.path.HOME)
      } else {
        this.$router.push(util.path.research.TAB_HISTORY)
      }
    }
  }
}
</script>
