<template>
  <v-toolbar app color="#FFF">
    <v-layout fill-height align-center justify-center>
      <v-img :src="require('@/assets/logo.png')" alt="Logo" max-width="40" max-height="40"></v-img>
      <v-btn
        flat
        color="primary"
        class="mr-0 hidden-sm-and-down"
        href="#questionnaire"
      >
        {{ $t('home.toolbar.inicio') }}
      </v-btn>
      <v-btn
        flat
        class="ma-0 hidden-sm-and-down"
        href="#knowMore"
      >
        {{ $t('home.toolbar.saberMais') }}
      </v-btn>
      <v-btn
        flat
        class="ma-0 hidden-sm-and-down"
        href="#researches"
      >
        {{ $t('home.toolbar.pesquisas') }}
      </v-btn>
      <v-btn
        flat
        class="ma-0 hidden-sm-and-down"
        href="#files"
      >
        {{ $t('home.toolbar.arquivos') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        dark
        small
        flat
        color="primary"
        @click="login()"
      >
        <v-icon small left dark>person</v-icon>
        {{ $t('home.toolbar.login') }}
      </v-btn>

      <lang-menu />

      <slot></slot>
    </v-layout>
  </v-toolbar>
</template>

<script>
import util from '@/router/util'
import LangMenu from '@/components/menu/language/LangMenu'

export default {
  name: 'HomeToolbar',
  components: {
    LangMenu
  },
  methods: {
    login () {
      this.$router.push(util.path.LOGIN)
    }
  }
}
</script>

<style scoped>
nav >>> .v-toolbar__content {
  padding-left: 2em;
  padding-right: 0;
}
.research-button {
  height: 115%;
}
</style>
