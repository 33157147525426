<template>
<v-container>
  <v-layout justify-center align-center>
    <v-flex sm11 md11 lg6>
      <v-card>
        <v-img
          :src="require('@/assets/banner.jpeg')"
          :lazy-src="require('@/assets/banner.jpeg')"
          alt="Menina no campo e segurando uma cesta de legumes"
          style="max-height: 60vh; background-size: cover;">
        </v-img>
        <v-card-title primary-title>
          <div>
            <div class="headline">{{ $t('tab.questionario.realizarQuestionario') }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-btn data-cy="adult" flat @click="openQuestionnaire(adult)">{{ $t('tab.questionario.adulto') }}</v-btn>
          <v-btn data-cy="adolescent" flat @click="openQuestionnaire(adolescent)" color="purple">{{ $t('tab.questionario.adolescente') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import firebase from 'firebase/app'
import { mapActions } from 'vuex'
import { QuestionnaireType } from '@/helpers/QuestionnaireType.js'
import util from '@/router/util'

export default {
  name: 'TabQuestionario',
  computed: {
    adult () {
      return QuestionnaireType.ADULT
    },
    adolescent () {
      return QuestionnaireType.ADOLESCENT
    }
  },
  methods: {
    ...mapActions(['loadQuestions', 'clearInterviewee']),
    openQuestionnaire (questionnaireType) {
      this.clearInterviewee()
      firebase.app().database().ref('/'.concat('questionario').concat('/').concat(this.$i18n.locale).concat('/').concat(questionnaireType))
        .once('value')
        .then(snap => {
          this.loadQuestions({ questions: snap.val(), questionnaireType, isResearch: true })
        })
        .catch(error => {
          console.log(error)
          this.$router.push(util.path.NOT_FOUND)
        })
    }
  }
}
</script>
