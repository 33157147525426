<template>
  <div :id="id" class="text-xs-center">
    <v-dialog
      v-model="isOn"
      width="500"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-3"
          primary-title
        >
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-layout justify-space-around>
            <know-more-button />
            <v-btn round large @click="$router.push('/')">{{ $t('home.toolbar.inicio') }}</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import KnowMoreDialogService from './KnowMoreDialogService'
import KnowMoreButton from '@/components/button/knowmore/KnowMoreButton'

export default {
  name: 'KnowMoreDialog',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    KnowMoreButton
  },
  data () {
    return {
      isOn: false,
      title: String,
      message: ''
    }
  },
  created () {
    KnowMoreDialogService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
      this.title = event.title
    })
  },
  destroyed () {
    KnowMoreDialogService.unsubscribe(this.id)
  },
  methods: {
    send () {
      this.$emit('sendHelpInformation', this.message)
      this.isOn = false
      this.message = ''
    }
  }
}
</script>
