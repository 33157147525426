import Vue from 'vue'

export default class ProgressService {
  static _eventBus = new Vue()

  static subscribe (id, callBack) {
    return ProgressService._eventBus.$on(id, callBack)
  }

  static unsubscribe (id) {
    return ProgressService._eventBus.$off(id)
  }

  static on (id, title, message) {
    ProgressService._eventBus.$emit(id, { isOn: true })
  }

  static off (id) {
    ProgressService._eventBus.$emit(id, { isOn: false })
  }
}
