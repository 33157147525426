<template>
  <v-layout justify-center fill-height>
    <v-flex sm8 md6>
      <v-card class="fill-height">
        <v-list two-line subheader>
          <v-list-tile class="pt-4" avatar>
            <v-list-tile-avatar>
              <v-icon class="amber white--text">language</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ $t('tab.configuracoes.idioma') }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ $t('tab.configuracoes.escolhaIdioma') }}</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <lang-menu />
            </v-list-tile-action>
          </v-list-tile>

          <vue-json-to-csv :json-data="json_data" :csv-title="'q8rn_'+ getUserData.displayName">
            <v-list-tile avatar @click="exportCSV">
              <v-list-tile-avatar>
                <v-icon class="black white--text">cloud_download</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>{{ $t('tab.configuracoes.exportar') }}</v-list-tile-title>
                <v-list-tile-sub-title>{{ $t('tab.configuracoes.exportarDados') }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-btn id="download" hidden />
          </vue-json-to-csv>

          <div id="download">
          </div>

          <v-list-tile avatar @click="help">
            <v-list-tile-avatar>
              <v-icon class="blue white--text">help</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ $t('tab.configuracoes.ajuda') }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ $t('tab.configuracoes.relatarProblema') }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-layout align-center justify-center>
            <v-list-tile avatar>
              <v-list-tile-content>
                <v-btn outline color="primary" @click="signOut">{{ $t('login.logout') }}</v-btn>
              </v-list-tile-content>
            </v-list-tile>
          </v-layout>
        </v-list>
      </v-card>
    </v-flex>
    <help-dialog id="Help" @sendHelpInformation="sendHelpInformation" />
  </v-layout>
</template>

<script >
import firebase from 'firebase/app'
import { mapActions, mapGetters } from 'vuex'
import LangMenu from '@/components/menu/language/LangMenu'
import HelpDialog from '@/components/dialogs/help/HelpDialog'
import HelpDialogService from '@/components/dialogs/help/HelpDialogService'
import SnackbarService from '@/components/snackbar/SnackbarService'
import util from '@/router/util'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  components: {
    LangMenu,
    HelpDialog,
    VueJsonToCsv
  },
  data () {
    return {
      json_data: []
    }
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  methods: {
    ...mapActions(['logout']),
    help () {
      HelpDialogService.on('Help')
    },
    signOut () {
      firebase.auth().signOut().then(() => {
        this.logout()
        this.$router.push(util.path.LOGIN)
      }).catch((error) => {
        console.log(error.code)
        console.log(error.message)
      })
    },
    sendHelpInformation (message) {
      let date = new Date().toLocaleString('en-US', { hour12: false })
      let user = firebase.auth().currentUser
      let ref = firebase.database().ref('help')
      ref.child(user.uid).child('name').set(user.displayName)
      ref.child(user.uid).child('email').set(user.email)
      ref.child(user.uid).child('messages').push({ date, message }, error => {
        if (error) {
          SnackbarService.on('Snackbar', this.$t('ajuda.msgErro'))
        } else {
          SnackbarService.on('Snackbar', this.$t('ajuda.msgSucesso'))
        }
      })
    },
    exportCSV () {
      firebase.app().database().ref('users/' + this.getUserData.uid + '/researches')
        .once('value')
        .then(snap => {
          if (snap.val()) {
            this.handleJson(snap.val())
          } else {
            SnackbarService.on('Snackbar', this.$t('tab.configuracoes.listaVazia'))
          }
        })
        .catch(error => {
          console.log(error)
          SnackbarService.on('Snackbar', this.$t('erro.Inesperado'))
        })
    },
    handleJson (data) {
      let json = Object.values(data)
      json.forEach(research => {
        research.code = research.code ? research.code : ''
        research.covid19 = ''
        research.hospitalTreatment = ''
        research.otherRefferedDisease = ''
        for (var [key, value] of Object.entries(research.interviewee)) {
          research[key] = value
        }
        delete research.interviewee
      })
      this.json_data = json
    }
  }
}
</script>
