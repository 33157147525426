<template>
  <v-layout column>
    <Toolbar :title="$t('escore.titulo')" itemsRight>
      <v-btn icon>
        <v-icon data-cy="home" color="white" @click="backMenu">home</v-icon>
      </v-btn>
    </Toolbar>
    <router-view />
    <KnowMoreDialog id="knowMore" />
  </v-layout>
</template>
<script>
import Toolbar from '@/components/toolbar/Toolbar'
// import util from '@/router/util'
import KnowMoreDialog from '@/components/dialogs/knowmore/KnowMoreDialog'
import KnowMoreDialogService from '@/components/dialogs/knowmore/KnowMoreDialogService'

export default {
  name: 'QuestionnaireScore',
  data: () => {
    return {
      items: [],
      classification: '...'
    }
  },
  components: {
    Toolbar,
    KnowMoreDialog
  },
  methods: {
    backMenu () {
      KnowMoreDialogService.on('knowMore', this.$t('escore.saberMaisPopUp.titulo'))
    }
  }
}
</script>
