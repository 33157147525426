<template>
  <v-layout column>
    <v-layout column>
      <v-flex class="pa-4" xs12 align-self-center>
        <div class="circle">{{ score }}</div>
        <h1 class="text-xs-center">{{ classification }}</h1>
      </v-flex>
      <v-layout justify-center fill-height>
        <v-flex sm12 md10>
          <v-card class="fill-height">
            <v-toolbar class="elevation-0 header">
              <v-layout>
                <v-flex xs12>
                  <v-toolbar-title>{{ $t('escore.pontosMelhorar') }}</v-toolbar-title>
                </v-flex>
                <v-flex>
                  <v-btn flat small :href="knowMoreUrl()" target="_blank" download color="primary" class="ma-0 pa-0">{{ $t('escore.cliqueSaberMais') }}</v-btn>
                </v-flex>
              </v-layout>
            </v-toolbar>
            <v-expansion-panel class="elevation-0">
              <v-expansion-panel-content
                v-for="item in items"
                :key="item.title"
                v-model="item.active"
                no-action
              >
                <template v-slot:header>
                  <v-list-tile>
                    <v-list-tile-avatar>
                      <v-img :src="require('@/' + item.icon)" alt="Logo" max-width="40" max-height="40"></v-img>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{ $t('escore.pontos') + ': ' + item.subtitle }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>

                <v-card v-for="subItem in item.items" :key="subItem.title">
                  <v-layout align-center>
                      <span class="pl-5">{{ subItem.points + '/' + subItem.maxPoints }}</span>
                      <v-card-text>{{ subItem.title }}</v-card-text>
                  </v-layout>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-layout>
</template>
<script>
import { QuestionnaireType } from '@/helpers/QuestionnaireType.js'
import util from '@/router/util'
import { mapGetters } from 'vuex'

export default {
  name: 'Score',
  data: () => {
    return {
      items: [],
      classification: '...'
    }
  },
  computed: {
    ...mapGetters(['getQuestions', 'getQuestionnaireType']),
    score () {
      return this.getQuestions
        .map(question => question.opcaoSelecionada)
        .reduce((a, b) => a + b, 0)
    }
  },
  mounted () {
    this.populateListByTheme(this.themes())
    this.setScoreClassification()
  },
  methods: {
    knowMoreUrl () {
      return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/panfleto_q8rn.pdf?alt=media&token=05a8dceb-6663-4af9-acc3-a0516bc2f352'
    },
    backMenu () {
      this.$router.push(util.path.research.TAB_HISTORY)
    },
    questionsToImprove () {
      return this.getQuestions.filter(question => question.opcaoSelecionada <= 2)
    },
    themes () {
      return this.questionsToImprove()
        .map(question => question.tema)
        .reduce((newList, index) => {
          if (!newList.some(element => element.titulo === index.titulo)) {
            newList.push(index)
          }
          return newList
        }, [])
    },
    sumPointsBy (theme) {
      return this.getQuestions
        .filter(question => question.tema.titulo === theme.titulo)
        .map(item => item.opcaoSelecionada)
        .reduce((a, b) => a + b, 0)
    },
    maxPointsBy (theme) {
      return this.getQuestions
        .filter(question => question.tema.titulo === theme.titulo)
        .map(question => question.opcoes[0].valor)
        .reduce((a, b) => a + b, 0)
    },
    populateListByTheme (themes) {
      for (const theme of themes) {
        let questions = this.questionsToImprove().filter(question => question.tema.titulo === theme.titulo)

        this.items.push({
          icon: theme.caminhoImagemTema,
          action: 'local_activity',
          title: theme.titulo,
          subtitle: this.sumPointsBy(theme) + '/' + this.maxPointsBy(theme),
          active: false,
          items: questions.map(item => {
            let result = {
              title: item.titulo,
              points: item.opcaoSelecionada,
              maxPoints: item.opcoes.map(option => option.valor).reduce((max, n) => n > max ? n : max)
            }
            return result
          })
        })
      }
    },
    setScoreClassification () {
      if (QuestionnaireType.ADULT === this.getQuestionnaireType) {
        this.classification = this.calculateAdultScoreClassification()
      } else {
        this.classification = this.calculateAdolescentScoreClassification()
      }
    },
    calculateAdultScoreClassification () {
      let scale = [25, 44, 58, 73, 88]
      return this.calculateScoreClassification(scale)
    },
    calculateAdolescentScoreClassification () {
      let scale = [22, 49, 51, 63, 76]
      return this.calculateScoreClassification(scale)
    },
    calculateScoreClassification (scale) {
      return this.score <= scale[0] ? this.$t('classificacoes.insuficiente')
        : this.score >= scale[0] + 1 && this.score <= scale[1] ? this.$t('classificacoes.regular')
          : this.score >= scale[1] + 1 && this.score <= scale[2] ? this.$t('classificacoes.bom')
            : this.score >= scale[2] + 1 && this.score <= scale[3] ? this.$t('classificacoes.muitoBom')
              : this.score >= scale[3] + 1 && this.score <= scale[4] ? this.$t('classificacoes.excelente')
                : this.$t('classificacoes.indefinido')
    }
  }
}
</script>

<style scoped>
.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  line-height: 120px;
  text-align: center;
  background: #C52C34;
}
.v-card__text {
  text-align: justify;
}
</style>
<style>
@media (max-width: 450px) {
  .header .v-toolbar__title {
    font-size: 1.1em;
  }
  .v-btn {
    font-size: 0.7em;
  }
}
</style>
