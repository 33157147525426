<template>
  <v-layout column>
    <v-tabs
      v-model="active"
      color="primary"
      dark
      slider-color="white"
    >
      <v-tab
        ripple
      >
        {{ $t('tab.historico.minhasPesquisas').concat(' (').concat(getTotalResearches).concat(')')}}
      </v-tab>
      <v-tab
        ripple
        v-if="getUserData.admin"
      >
        {{ $t('tab.historico.usuarios') }}
      </v-tab>
    </v-tabs>
    <research-list v-if="active === 0"/>
    <admin-researches-list v-if="active === 1"/>
  </v-layout>
</template>

<script>
import ResearchList from '@/components/list/ResearchList'
import AdminResearchesList from '@/components/list/AdminResearchesList'
import { mapGetters } from 'vuex'

export default {
  name: 'History',
  data: () => {
    return {
      active: null
    }
  },
  components: {
    ResearchList,
    AdminResearchesList
  },
  computed: {
    ...mapGetters(['getUserData', 'getTotalResearches'])
  }
}
</script>
