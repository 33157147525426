<template>
  <v-layout fill-height>
    <toolbar :pathBackButton="util.path.research.TAB_HISTORY" :title="getViewHistory.researches.displayName" />
    <v-layout row justify-start fill-height>
      <v-flex v-if="researches.length">
        <v-list two-line class="fill-height">
          <template v-for="(research, index) in researches">
            <v-subheader v-if="research.header" :key="research.header">{{ research.header }}</v-subheader>

            <v-list-tile :key="research.title" avatar @click="viewInformation(research.interviewee)">
              <v-list-tile-avatar>
                <img :src="research.avatar" alt="Avatar">
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title v-html="research.title"></v-list-tile-title>
                <v-list-tile-sub-title class="text--primary">{{ research.subtitle }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-title v-if="research.code" v-html="research.code"></v-list-tile-title>
                <v-list-tile-sub-title>{{ $t('tab.historico.escore') }}: {{ research.escore }}</v-list-tile-sub-title>
              </v-list-tile-action>
            </v-list-tile>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-flex>
      <v-layout v-else-if="empty" align-center justify-center row>
        <div class="subheading font-weight-light">{{ $t('tab.historico.naoExistemQuestionarios') }}</div>
      </v-layout>
      <Progress id="Progress" />
    </v-layout>
  </v-layout>
</template>

<script>
import firebase from 'firebase/app'
import util from '@/router/util'
import Toolbar from '@/components/toolbar/Toolbar'
import { mapActions, mapGetters } from 'vuex'
import ProgressService from '@/components/progress/ProgressService'
import Progress from '@/components/progress/Progress'

export default {
  name: 'UserResearches',
  components: {
    Toolbar,
    Progress
  },
  data: () => {
    return {
      util: util,
      researches: [],
      empty: false
    }
  },
  computed: {
    ...mapGetters(['getViewHistory'])
  },
  mounted () {
    ProgressService.on('Progress')

    if (this.$route.params.uid) {
      this.storeViewHistory({ uid: this.$route.params.uid, displayName: this.$route.params.username })
    }

    firebase.app().database().ref('users/' + this.getViewHistory.researches.uid + '/researches')
      .once('value')
      .then(snap => {
        if (snap.val()) {
          let researches = Object.values(snap.val())
          researches.forEach(item => {
            let i = {
              avatar: item.interviewee.gender === 'male' ? require('@/assets/male.png') : require('@/assets/female.png'),
              title: item.interviewee.nameInitials,
              subtitle: item.date,
              escore: item.answers.reduce((a, b) => a + b, 0),
              identificationCode: item.interviewee.identificationCode,
              interviewee: item.interviewee,
              code: item.code
            }
            this.researches.push(i)
          })
        } else {
          this.empty = true
        }
        ProgressService.off('Progress')
      })
      .catch(() => {
        this.$router.push(util.path.NOT_FOUND)
      })
  },
  methods: {
    ...mapActions(['storeViewHistory']),
    viewInformation (interviewee) {
      this.$router.push({
        name: 'Form',
        params: {
          interviewee,
          pathBackButton: util.path.research.USER_RESEARCHES
        }
      })
    }
  }
}
</script>
