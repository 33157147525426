<template>
  <v-layout fill-height>
    <toolbar :title="$t('termos.titulo')" />
    <v-layout column>
      <iframe :src="`https://docs.google.com/viewer?srcid=${documentId}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`" width="100%" height="100%"></iframe>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout column class="pl-4 pr-4 pb-2">
          <v-btn flat small :href="documentLink" target="_blank" download color="primary">{{ $t('termos.baixar') }}</v-btn>
          <v-flex>
            <v-checkbox
              data-cy="terms-checkbox"
              v-model="checkbox"
              :label="$t('termos.checkbox')"
            ></v-checkbox>
          </v-flex>
          <v-flex>
            <v-text-field
              v-if="collectEmail"
              data-cy="email"
              v-model="email"
              :label="$t('entrevistado.email')"
              :rules="emailRules"
              box
              required
            ></v-text-field>
          </v-flex>
          <v-flex v-if="getUserData.code.fields.phone">
            <v-text-field
              data-cy="phone"
              type="tel"
              v-model="phone"
              :label="$t('entrevistado.telefone')"
              :rules="phoneRules"
              mask="(##) #####-####"
              box
              required
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-btn data-cy="terms-proceed" block large color="primary" @click="proceed()">{{ $t('termos.botao') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-layout>
  </v-layout>
</template>

<script>
import Toolbar from '@/components/toolbar/Toolbar'
import util from '@/router/util'
import DialogService from '@/components/dialogs/generic/DialogService'
import { mapGetters, mapActions } from 'vuex'
import { QuestionnaireType } from '@/helpers/QuestionnaireType.js'
import firebase from 'firebase/app'

export default {
  name: 'TermsAndCondition',
  data: () => {
    return {
      valid: false,
      checkbox: false,
      documentId: '',
      documentLink: '',
      email: '',
      phone: '',
      emailRules: [],
      phoneRules: []
    }
  },
  components: {
    Toolbar
  },
  mounted () {
    firebase.database().ref('codes')
      .orderByChild('id')
      .equalTo(this.getUserData.code.id)
      .once('value')
      .then(snap => {
        this.documentId = Object.values(snap.val())[0].terms.documentId
        this.documentLink = Object.values(snap.val())[0].terms.downloadLink
      })
      .catch(error => {
        console.debug(error)
      })
    this.phoneRules = [(v) => !!v || this.$t('validacao.campoObrigatorio')]
    this.emailRules = [
      (v) => !!v || this.$t('validacao.campoObrigatorio'),
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || this.$t('validacao.emailValido')
      }
    ]
  },
  computed: {
    ...mapGetters(['getUserData']),
    collectEmail () {
      return this.getUserData.collectEmail
    }
  },
  methods: {
    ...mapActions(['loadQuestions', 'clearInterviewee', 'saveEmail', 'savePhone']),
    openQuestionnaire (questionnaireType) {
      this.clearInterviewee()
      firebase.app().database().ref('/'.concat('questionario').concat('/').concat(this.$i18n.locale).concat('/').concat(questionnaireType))
        .once('value')
        .then(snap => {
          this.saveEmail(this.email)
          this.savePhone(this.phone)
          this.loadQuestions({ questions: snap.val(), questionnaireType, isResearch: true })
        })
        .catch(error => {
          console.log(error)
          this.$router.push(util.path.NOT_FOUND)
        })
    },
    proceed () {
      if (!this.checkbox) {
        DialogService.on('DefaultDialog', this.$t('termos.modal.titulo'), this.$t('termos.modal.mensagem.termo'))
      } else if (!this.$refs.form.validate()) {
        DialogService.on('DefaultDialog', this.$t('termos.modal.titulo'), this.$t('termos.modal.mensagem.email'))
      } else {
        this.openQuestionnaire(QuestionnaireType.ADULT)
      }
    }
  }
}
</script>

<style scoped>
.fixed-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
}
</style>
<style>
.v-btn__content {
  flex: 1 1 auto;
  white-space: normal;
}
</style>
