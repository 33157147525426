<template>
  <v-container
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <v-layout align-center>
      <v-flex text-xs-center>
        <h1 class="display-1 primary--text">Obrigado!</h1>
        <p>Obrigado por participar desta pesquisa sobre a sua experiência com o programa “Vida e Saúde” da TV Novo Tempo.</p>
        <v-btn
          data-cy="thankYou"
          @click="redirect()"
          color="primary"
        >
          Ver sua pontuação
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import util from '@/router/util'

export default {
  name: 'ThankYou',
  methods: {
    redirect () {
      this.$router.push(util.path.research.SCORE)
    }
  }
}
</script>
