<template>
  <v-app>
    <Progress id="Progress" />
    <Onboarding v-if="!loading && showOnboarding"/>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import SnackbarService from '@/components/snackbar/SnackbarService'
import { mapGetters, mapActions } from 'vuex'
import ProgressService from '@/components/progress/ProgressService'
import Progress from '@/components/progress/Progress'
import Onboarding from '@/components/onboarding/Onboarding'
import util from '@/router/util'

export default {
  name: 'CodeLogin',
  components: {
    Progress,
    Onboarding
  },
  data: () => {
    return {
      valid: false,
      email: null,
      code: null,
      fieldRules: [],
      emailRules: [],
      loading: false,
      showOnboarding: false
    }
  },
  mounted () {
    this.fieldRules = [v => !!v || this.$t('validacao.campoObrigatorio')]
    this.emailRules = [
      v => !!v || this.$t('validacao.campoObrigatorio'),
      v => /.+@.+/.test(v) || this.$t('validacao.emailValido')
    ]
    this.loading = true
    ProgressService.on('Progress')
    this.anonymousLogin(this.$route.params.code)
  },
  computed: {
    ...mapGetters['getUserData']
  },
  methods: {
    ...mapActions(['fetchUser']),
    anonymousLogin (code) {
      firebase.auth().signInAnonymously()
        .then(() => {
          let user = firebase.auth().currentUser
          user.code = { id: code }

          firebase.database().ref().child('codes')
            .orderByChild('id')
            .equalTo(user.code.id)
            .once('value')
            .then(snap => {
              if (snap.exists()) {
                if (Object.values(snap.val())[0].closed) {
                  this.$router.push(util.path.HOME)
                  return
                }

                user.collectEmail = Object.values(snap.val())[0].collectEmail
                user.code.fields = Object.values(snap.val())[0].fields
                this.showOnboarding = user.code.fields.vidaSaude !== undefined
                if (!user.code.fields) {
                  user.code.fields = {}
                }
                this.fetchUser(user)
                ProgressService.off('Progress')
                this.loading = false
                if (!this.showOnboarding) {
                  this.$router.push(util.path.research.TERMS_AND_CONDITION)
                }
              } else {
                ProgressService.off('Progress')
                this.loading = false
                SnackbarService.on('Snackbar', this.$t('codeLogin.codigoInvalido'))
              }
            })
        })
        .catch(error => {
          ProgressService.off('Progress')
          console.debug(error)
          SnackbarService.on('Snackbar', this.$t('erroInesperado'))
        })
    }
  }
}
</script>
