<template>
  <v-container
    fill-height
    style="height: calc(100vh - 58px);"
  >
    <v-layout align-center>
      <v-flex text-xs-center>
        <h1 class="display-1 primary--text">Mídia televisiva e sua influência no estilo de vida e saúde</h1>
        <p>Olá! Obrigado por participar desta pesquisa sobre a sua experiência com o programa “Vida e Saúde” da TV Novo Tempo.
As explicações sobre este estudo se encontram no “Termo de Consentimento Livre e Esclarecido”. Ao aceitar você terá acesso às perguntas.
Ao concluir, você verá a pontuação final que indica o seu padrão de estilo de vida e os “Pontos a Melhorar”. Ao clicar no “Saiba Mais”, você terá acesso a um livreto sobre os “Oito Remédios Naturais”.</p>
        <v-btn
          data-cy="onboarding"
          @click="redirectToTermsAndConditions()"
          color="primary"
        >
          Começar!
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import util from '@/router/util'

export default {
  name: 'Onboarding',
  methods: {
    redirectToTermsAndConditions () {
      this.$router.push(util.path.research.TERMS_AND_CONDITION)
    }
  }
}
</script>
