<template>
  <v-layout row justify-start fill-height>
    <v-flex v-if="items.length">
      <v-list two-line class="fill-height">
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header">{{ item.header }}</v-subheader>

          <v-list-tile :key="item.title" avatar @click="viewUserResearches(item)">
            <v-list-tile-avatar>
              <img :src="item.avatar" alt="Avatar">
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title class="text--primary">{{ $t('tab.historico.instituicao') + ': ' + item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-list-tile-sub-title>{{ $t('tab.historico.pesquisas') + ': ' + item.quantity }}</v-list-tile-sub-title>
            </v-list-tile-action>
          </v-list-tile>

          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
    <v-layout v-else-if="empty" align-center justify-center row>
      <div class="subheading font-weight-light">{{ $t('tab.historico.naoExistemQuestionarios') }}</div>
    </v-layout>
    <Progress id="Progress" />
  </v-layout>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters } from 'vuex'
import ProgressService from '@/components/progress/ProgressService'
import Progress from '@/components/progress/Progress'

export default {
  name: 'AdminResearchesList',
  data: () => {
    return {
      items: [],
      users: [],
      empty: false
    }
  },
  components: {
    Progress
  },
  mounted () {
    ProgressService.on('Progress')

    let currentUser = firebase.auth().currentUser
    firebase.app().database().ref('users')
      .once('value')
      .then(snap => {
        let users = Object.values(snap.val()).filter(user => user.uid !== currentUser.uid)
        if (users.length) {
          users.forEach(item => {
            let i = {
              title: item.name,
              subtitle: item.institution,
              quantity: item.researches ? Object.values(item.researches).length : 0,
              avatar: require('@/assets/user.png'),
              uid: item.uid
            }
            this.items.push(i)
          })
        } else {
          this.empty = true
        }
        ProgressService.off('Progress')
      })
      .catch(error => {
        console.log(error)
      })
  },
  computed: {
    ...mapGetters(['getUserData'])
  },
  methods: {
    viewUserResearches (user) {
      this.$router.push({
        name: 'UserResearches',
        params: {
          username: user.title,
          uid: user.uid
        }
      })
    }
  }
}
</script>
