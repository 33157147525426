<template>
  <v-menu offset-y class="min-width: 1000px">
    <template v-slot:activator="{ on }">
      <v-btn
        data-cy="lang"
        flat
        v-on="on"
      >
        <v-img :src="require('@/assets/' + language.icon + '.svg')" alt="Logo" max-width="30" max-height="30"></v-img>
        <v-icon small dark>arrow_drop_down</v-icon>
      </v-btn>
    </template>
    <v-flex>
      <v-list>
        <v-list-tile
          data-cy="lang-select"
          v-for="(language, index) in languages"
          :key="index"
          @click="changeLanguage(language)"
        >
          <v-list-tile-title>{{ language.text }}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-flex>
  </v-menu>
</template>

<script>
export default {
  name: 'LangMenu',
  data () {
    return {
      language: Object,
      languages: [
        { text: 'Português (BR)', value: 'pt-br', icon: 'brazil' },
        { text: 'English (US)', value: 'en', icon: 'usa' },
        { text: 'Español', value: 'es', icon: 'spain' },
        { text: 'Français', value: 'fr', icon: 'france' },
        { text: 'Deutsch', value: 'de', icon: 'german' },
        { text: 'عربي', value: 'ar', icon: 'arabic' }
      ]
    }
  },
  created () {
    this.language = this.languages.filter(lang => lang.value === this.$i18n.locale)[0]
    this.language = this.language ? this.language : this.languages[0]
  },
  methods: {
    changeLanguage (selectedLanguage) {
      this.language = selectedLanguage
      this.$i18n.locale = selectedLanguage.value
    }
  }
}
</script>
