<template>
  <v-layout column fill-height>
    <home-toolbar />
    <v-layout id="questionnaire" column class="section">
      <v-img
        class="white--text"
        style="max-height: 100vh"
        :src="require('@/assets/banner.jpeg')"
        :lazy-src="require('@/assets/banner.jpeg')"
        alt="Menina no campo e segurando uma cesta de legumes"
        gradient="to top right, rgba(100,115,201,.22), rgba(25,32,72,.2)"
      >
        <v-container fill-height fluid>
          <v-layout column v-if="$vuetify.breakpoint.xs">
            <v-flex sm12>
            </v-flex>
            <v-flex sm12 class="justify-self-end">
              <p class="headline font-weight-bold">{{ $t('home.section.questionario.titulo') }}</p>
              <p class="subheading">{{ $t('home.section.questionario.texto') }}</p>
              <v-btn data-cy="adult" round class="fixed-width" large @click="openQuestionnaire(adult)" color="primary">{{ $t('tab.questionario.adulto') }}</v-btn>
              <v-btn data-cy="adolescent" round class="fixed-width" large @click="openQuestionnaire(adolescent)">{{ $t('tab.questionario.adolescente') }}</v-btn>
            </v-flex>
          </v-layout>
          <v-layout align-center v-if="!$vuetify.breakpoint.xs">
            <v-flex xs12>
              <p class="display-2 font-weight-bold">{{ $t('home.section.questionario.titulo') }}</p>
              <p class="headline pb-2">{{ $t('home.section.questionario.texto') }}</p>
              <v-btn data-cy="adult" round class="fixed-width" large @click="openQuestionnaire(adult)" color="primary">{{ $t('tab.questionario.adulto') }}</v-btn>
              <v-btn data-cy="adolescent" round class="fixed-width" large @click="openQuestionnaire(adolescent)">{{ $t('tab.questionario.adolescente') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
        <template v-slot:placeholder>
          <v-layout
            align-center
            justify-center
            ma-0
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-layout>
        </template>
      </v-img>
    </v-layout>

    <v-layout id="knowMore" row justify-center align-center class="section" v-if="!$vuetify.breakpoint.xs">
      <v-flex xs12 sm6 offset-sm1>
        <v-flex
          xs10
        >
          <v-img
            class="device"
            style="width: 30vh"
            :src="require('@/assets/device/questionnaire_device_' + $i18n.locale + '.png')"
            :lazy-src="require('@/assets/device/questionnaire_device_' + $i18n.locale + '.png')"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-flex>
      </v-flex>
      <v-flex xs12 sm6 class="mr-5">
        <v-layout column>
          <v-flex class="mb-4 mt-4">
            <p class="display-2 font-weight-bold">{{ $t('home.section.saberMais.titulo') }}</p>
            <p class="headline">{{ $t('home.section.saberMais.texto') }}</p>
            <know-more-button />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout id="researches" column justify-center align-center class="section" v-if="$vuetify.breakpoint.xs">
      <v-flex xs12 class="pt-5">
        <v-layout column align-center>
            <p class="headline font-weight-bold">{{ $t('home.section.saberMais.titulo') }}</p>
            <p class="body-2">{{ $t('home.section.saberMais.texto') }}</p>
            <know-more-button />
        </v-layout>
      </v-flex>
      <v-flex xs12 class="pb-5">
        <v-layout justify-center>
          <v-img
            class="device"
            style="width: 30vh;"
            :src="require('@/assets/device/questionnaire_device_' + $i18n.locale + '.png')"
            :lazy-src="require('@/assets/device/questionnaire_device_' + $i18n.locale + '.png')"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout id="researches" row justify-center align-center class="section red darken-2" v-if="!$vuetify.breakpoint.xs">
      <v-flex xs12 sm6 offset-sm1>
        <p class="display-2 font-weight-bold white--text">{{ $t('home.section.pesquisas.titulo') }}</p>
        <p class="headline white--text">{{ $t('home.section.pesquisas.texto') }}</p>
        <v-btn round medium class="pa-4" @click="login()">{{ $t('home.section.pesquisas.botao') }}</v-btn>
        <v-btn round medium class="pa-4" @click="researchByCode()">{{ $t('home.section.pesquisas.tenhoUmCodigo') }}</v-btn>
      </v-flex>
      <v-flex xs12 sm6 offset-sm1>
        <v-flex
          xs10
        >
          <v-img
            class="device"
            style="width: 30vh"
            :src="require('@/assets/device/score_device_' + $i18n.locale + '.png')"
            :lazy-src="require('@/assets/device/score_device_' + $i18n.locale + '.png')"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-layout id="researches" column justify-center align-center class="section red darken-2" v-if="$vuetify.breakpoint.xs">
      <v-flex xs12 class="pt-5">
        <v-layout column align-center>
          <p class="headline font-weight-bold white--text">{{ $t('home.section.pesquisas.titulo') }}</p>
          <p class="body-2 text-xs-center white--text">{{ $t('home.section.pesquisas.texto') }}</p>
          <v-btn round medium @click="login()">{{ $t('home.section.pesquisas.botao') }}</v-btn>
          <v-btn round medium @click="researchByCode()">{{ $t('home.section.pesquisas.tenhoUmCodigo') }}</v-btn>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="pb-5">
        <v-layout justify-center>
          <v-img
            class="device"
            style="width: 30vh;"
            :src="require('@/assets/device/score_device_' + $i18n.locale + '.png')"
            :lazy-src="require('@/assets/device/score_device_' + $i18n.locale + '.png')"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout id="files" row justify-center align-center class="section" v-if="!$vuetify.breakpoint.xs">
      <v-flex xs12 sm6 offset-sm1>
        <v-flex
          xs10
        >
          <v-img
            class="device"
            style="width: 60vh"
            :src="require('@/assets/' + $i18n.locale + '_pdf.png')"
            :lazy-src="require('@/assets/' + $i18n.locale + '_pdf.png')"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-flex>
      </v-flex>
      <v-flex xs12 sm6 offset-sm1 class="ml-0 mr-5">
        <v-layout column>
          <v-flex class="mb-4 mt-4">
            <p class="display-2 font-weight-bold">{{ $t('home.section.arquivos.titulo') }}</p>
            <p class="headline">{{ $t('home.section.arquivos.texto') }}</p>
            <v-btn round large outline class="fixed-width pa-4" :href="adultFilesUrl()" target="_blank" color="primary">{{ $t('tab.questionario.adulto') }}</v-btn>
            <v-btn round large outline class="fixed-width pa-4" :href="adolescentFilesUrl()" target="_blank" color="primary">{{ $t('tab.questionario.adolescente') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout id="files" column justify-center align-center class="section" v-if="$vuetify.breakpoint.xs">
      <v-flex xs12 class="pt-5">
        <v-layout column align-center>
          <p class="headline font-weight-bold">{{ $t('home.section.arquivos.titulo') }}</p>
          <p class="body-2">{{ $t('home.section.arquivos.texto') }}</p>
          <v-btn round outline class="fixed-width" :href="adultFilesUrl()" target="_blank" color="primary">{{ $t('tab.questionario.adulto') }}</v-btn>
          <v-btn round outline class="fixed-width" :href="adolescentFilesUrl()" target="_blank" color="primary">{{ $t('tab.questionario.adolescente') }}</v-btn>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="pb-5">
        <v-layout justify-center>
          <v-img
            class="device"
            style="width: 30vh;"
            :src="require('@/assets/' + $i18n.locale + '_pdf.png')"
            :lazy-src="require('@/assets/' + $i18n.locale + '_pdf.png')"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-layout>
            </template>
          </v-img>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout column>
      <v-footer
        height="auto"
        width="100%"
        dark
        class="pa-4"
      >
        <v-flex
          py-2
          text-xs-center
          white--text
          xs12
        >
          <p>{{ $t('home.footer.grupo') }}</p>
          <p>{{ $t('home.footer.programa') }}</p>
          <p>{{ $t('home.footer.unasp') }}</p>
          <p>{{ $t('home.footer.contato') }}</p>
          &copy;2022 — <strong>Q8RN</strong>
        </v-flex>
      </v-footer>
    </v-layout>
  </v-layout>
</template>

<script>
import util from '@/router/util'
import firebase from 'firebase/app'
import 'firebase/analytics'
import { mapActions } from 'vuex'
import { QuestionnaireType } from '@/helpers/QuestionnaireType.js'
import HomeToolbar from '@/components/toolbar/HomeToolbar'
import KnowMoreButton from '@/components/button/knowmore/KnowMoreButton'

export default {
  name: 'Home',
  components: {
    HomeToolbar,
    KnowMoreButton
  },
  computed: {
    adult () {
      return QuestionnaireType.ADULT
    },
    adolescent () {
      return QuestionnaireType.ADOLESCENT
    }
  },
  methods: {
    ...mapActions(['loadQuestions', 'clearInterviewee']),
    openQuestionnaire (questionnaireType) {
      firebase.analytics().logEvent(`clique questionario ${questionnaireType}`)
      this.clearInterviewee()
      firebase.app().database().ref('/'.concat('questionario').concat('/').concat(this.$i18n.locale).concat('/').concat(questionnaireType))
        .once('value')
        .then(snap => {
          this.loadQuestions({ questions: snap.val(), questionnaireType, isResearch: false })
        })
        .catch(error => {
          console.log(error)
          this.$router.push(util.path.NOT_FOUND)
        })
    },
    login () {
      this.$router.push(util.path.LOGIN)
    },
    researchByCode () {
      this.$router.push(util.path.research.CODE_LOGIN)
    },
    knowMoreUrl () {
      return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/panfleto_q8rn.pdf?alt=media&token=05a8dceb-6663-4af9-acc3-a0516bc2f352'
    },
    adultFilesUrl () {
      if (this.$i18n.locale === 'pt-br') {
        return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/questionnaire%2Fpt-br_adult_q8rn.pdf?alt=media&token=223c2d47-9e23-4c87-abe2-922cd8ddb9b6'
      }
      if (this.$i18n.locale === 'en') {
        return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/questionnaire%2Fen_adult_q8rn.pdf?alt=media&token=a695e6e9-0ebb-493d-b23d-8f23bfbee046'
      }
      if (this.$i18n.locale === 'es') {
        return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/questionnaire%2Fes_adult_q8rn.pdf?alt=media&token=32607d68-1d33-4ecd-b2bc-239760cb3377'
      }
    },
    adolescentFilesUrl () {
      if (this.$i18n.locale === 'pt-br') {
        return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/questionnaire%2Fpt-br_adolescent_q8rn.pdf?alt=media&token=963202cc-9245-4aea-a354-0d7a0f76ec85'
      }
      if (this.$i18n.locale === 'en') {
        return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/questionnaire%2Fen_adolescent_q8rn.pdf?alt=media&token=fb563410-b2c4-4b8d-ab12-0ee050440d82'
      }
      if (this.$i18n.locale === 'es') {
        return 'https://firebasestorage.googleapis.com/v0/b/q8rn-pwa.appspot.com/o/questionnaire%2Fes_adolescent_q8rn.pdf?alt=media&token=a5e41504-fb86-456a-9c59-6b48245be18e'
      }
    }
  }
}
</script>

<style>
.fixed-width {
  width: 150px;
}
.section {
  height: 100vh;
}
.device {
  box-sizing: border-box;
  max-width: 80%;
  max-height: 80vh;
}
</style>
