<template>
  <div class="parent" v-if="isOn">
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      class="progress-circular"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
import ProgressService from './ProgressService'

export default {
  name: 'Progress',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isOn: false
    }
  },
  created () {
    ProgressService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
    })
  },
  destroyed () {
    ProgressService.unsubscribe(this.id)
  }
}
</script>

<style>
.parent {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.04);
  z-index: 2;
}
.progress-circular {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
