<template>
  <v-layout column>
    <toolbar :pathBackButton='util.path.research.FORM' isFlat>
      <img :src='getImage' alt='Logo referente ao tema' width='40px' height='40px'>
      <h3 class='white--text title pl-3'>{{ getTheme }}</h3>
    </toolbar>
    <v-layout column justify-center class="header primary white--text">
      <div class="caption text-xs-center">QUESTIONÁRIO OITO REMÉDIOS NATURAIS - Q8RN</div>
      <div class="caption text-xs-center">Avaliação do Estilo de Vida de Adultos</div>
      <div class="caption text-xs-center">Orientações: Marque a opção que mais se aproxima da sua prática nos últimos três meses</div>
    </v-layout>
    <v-layout column fill-height class="pagination">
      <v-flex>
        <v-card elevation='4'>
          <v-card-text class='pa-2'>
            <div class='text-xs-center'>
              <v-pagination
                circle
                v-model='page'
                :length='getQuestions.length'
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-container>
        <v-layout align-center justify-center>
          <v-flex>
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
    <v-fab-transition>
      <v-btn
        data-cy='forward'
        dark
        fab
        fixed
        bottom
        right
        color='primary'
        @click='next'
      >
        <v-icon>arrow_forward</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-layout>
</template>

<script>
import firebase from 'firebase/app'
import Toolbar from '@/components/toolbar/Toolbar'
import DialogService from '@/components/dialogs/generic/DialogService'
import util from '@/router/util'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Questionnaire',
  components: {
    Toolbar
  },
  data: () => {
    return {
      util: util
    }
  },
  computed: {
    ...mapGetters(['getQuestions', 'getPageNumber', 'getQuestionnaireType', 'getInterviewee', 'getUserData', 'getEmail', 'getPhone']),
    page: {
      get: function () {
        return parseInt(this.$route.params.num)
      },
      set: function (page) {
        this.updatePageNumber({
          page,
          questionnaireType: this.getQuestionnaireType,
          isResearch: true
        })
      }
    },
    getTheme () {
      return this.getQuestions[this.$route.params.num - 1].tema.titulo
    },
    getImage () {
      return require('@/' + this.getQuestions[this.$route.params.num - 1].tema.caminhoImagemTema)
    }
  },
  methods: {
    ...mapActions(['updatePageNumber', 'logout', 'clearInterviewee']),
    next () {
      if (this.currentQuestionAnswered()) {
        if (!this.isLastQuestion()) {
          this.$route.params.num++
          this.updatePageNumber({
            page: parseInt(this.$route.params.num),
            questionnaireType: this.getQuestionnaireType,
            isResearch: true
          })
        } else if (this.allQuestionsAnswered()) {
          this.saveResearch()
          if (this.getUserData.code.fields.thankYou) {
            this.$router.push(util.path.research.THANK_YOU)
          } else {
            this.$router.push(util.path.research.SCORE)
          }
        } else {
          DialogService.on('DefaultDialog', this.$t('questionario.respostasObrigatorias'), this.$t('questionario.preenchaAsQuestoes'))
        }
      } else {
        DialogService.on('DefaultDialog', this.$t('questionario.respostaObrigatoria'), this.$t('questionario.escolhaUmaResposta'))
      }
    },
    saveResearch () {
      let user = this.getUserData
      let date = new Date().toLocaleString('pt-br', { hour12: false })

      if (!user) {
        this.logout()
        this.$router.push(util.path.LOGIN)
      }

      if (user.isAnonym) {
        firebase.database().ref().child('codes')
          .orderByChild('id')
          .equalTo(user.code.id)
          .once('value')
          .then(snap => {
            if (snap.exists()) {
              const parentUid = Object.values(snap.val())[0].uid
              this.persistResearch(parentUid,
                {
                  date,
                  code: user.code.id,
                  email: this.getEmail,
                  phone: this.getPhone,
                  interviewee: this.getInterviewee,
                  answers: this.getQuestions.map(question => question.opcaoSelecionada)
                }
              )
            }
          })
      } else {
        this.persistResearch(user.uid,
          {
            date,
            interviewee: this.getInterviewee,
            answers: this.getQuestions.map(question => question.opcaoSelecionada)
          }
        )
      }
    },
    persistResearch (uid, payload) {
      const isEmpty = Object.values(payload.interviewee).every(x => (x === null || x === ''))

      if (!isEmpty) {
        let ref = firebase.database().ref('users')
        ref.child(uid).child('researches').push(payload)
        this.clearInterviewee()
      }
    },
    isLastQuestion () {
      return this.getQuestions[this.$route.params.num - 1].numero === this.getQuestions.length
    },
    currentQuestionAnswered () {
      return this.getQuestions[this.$route.params.num - 1].opcaoSelecionada !== ''
    },
    allQuestionsAnswered () {
      return this.getQuestions.filter(question => question.opcaoSelecionada === '').length === 0
    }
  }
}
</script>

<style scoped>
 .header {
  position: absolute;
  width: 100%;
  height: 72px;
}

.pagination {
  margin-top: 72px;
}
</style>
