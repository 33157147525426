<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid fill-height style="background-color: #C52C34">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4 xl3>
            <v-card class="pa-4 elevation-1">
              <v-layout align-center justify-center class="pt-4">
                <p class="title">{{ $t('cadastro.titulo') }}</p>
              </v-layout>
              <v-card-text>
                <v-form v-model="valid" ref="form" @submit="signUp">
                  <v-text-field
                    :label="$t('cadastro.nome')"
                    name="name"
                    prepend-icon="person"
                    type="text"
                    v-model="name"
                    :rules='fieldRules'
                    counter="40"
                    required
                  ></v-text-field>

                  <v-text-field
                    :label="$t('login.email')"
                    name="email"
                    prepend-icon="email"
                    type="email"
                    v-model="email"
                    :rules='emailRules'
                    required
                  ></v-text-field>

                  <v-text-field
                    id="institution"
                    :label="$t('cadastro.instituicao')"
                    name="institution"
                    prepend-icon="assignment"
                    type="text"
                    v-model="institution"
                    :rules='fieldRules'
                    counter="40"
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    :label="$t('login.password')"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    v-model="password"
                    :rules='fieldRules'
                    required
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-layout justify-end>
                    <v-btn block data-cy="signin" class="mt-4 mb-2" type="submit" color="primary" :disabled="!valid" @click="signUp">{{ $t('cadastro.cadastrar') }}</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import util from '@/router/util'
import { mapActions } from 'vuex'
import SnackbarService from '@/components/snackbar/SnackbarService'
import DialogService from '@/components/dialogs/generic/DialogService'

export default {
  name: 'Signup',
  data: () => {
    return {
      valid: false,
      name: null,
      email: null,
      institution: null,
      password: null,
      fieldRules: [],
      emailRules: []
    }
  },
  mounted () {
    this.fieldRules = [v => !!v || this.$t('validacao.campoObrigatorio')]
    this.emailRules = [
      v => !!v || this.$t('validacao.campoObrigatorio'),
      v => /.+@.+/.test(v) || this.$t('validacao.emailValido')
    ]
  },
  methods: {
    ...mapActions(['signUp', 'saveCurrentUser']),
    signUp (e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then(data => {
            let user = firebase.auth().currentUser
            user.updateProfile({
              displayName: this.name
            }).then(() => {
              this.saveUserInformationFirebase(user)
              this.sendEmail(user)
            }).catch(error => {
              console.debug(error)
              SnackbarService.on('Snackbar', this.$t('erroInesperado'))
            })
          })
          .catch(error => {
            if (error.code === 'auth/email-already-in-use') {
              SnackbarService.on('Snackbar', this.$t('validacao.emailJaUtilizado'))
            } else if (error.code === 'auth/weak-password') {
              SnackbarService.on('Snackbar', this.$t('validacao.senhaFraca'))
            } else {
              SnackbarService.on('Snackbar', error.message)
            }
          })
      }
    },
    saveUserInformationFirebase (user) {
      let ref = firebase.database().ref('users')
      ref.child(user.uid).child('name').set(this.name)
      ref.child(user.uid).child('institution').set(this.institution)
      ref.child(user.uid).child('uid').set(user.uid)
    },
    sendEmail (user) {
      user.sendEmailVerification().then(() => {
        DialogService.on('DefaultDialog', this.$t('login.emailVerificacao.titulo'), this.$t('login.emailVerificacao.mensagem'))
        this.$router.push(util.path.LOGIN)
      }).catch(error => {
        console.debug(error)
        SnackbarService.on('Snackbar', error)
      })
    }
  }
}
</script>
